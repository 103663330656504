import React, { useState, useEffect, useRef } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { getUserByUid } from '../../../controllers/usuarios';
import { getClienteByID } from '../../../controllers/clientes';
import { getFacturaArchivos } from '../../../controllers/facturama';
import { getNotaCreditoById } from '../../../controllers/viajes';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import xml2js from 'xml2js';

import axios from 'axios';


const ModalCreateEdit = (props) => {
    //constantes

    //estados de control de modal y registro
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);
    const [user, setUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [fecha, setFecha] = useState('');
    const [firstDay, setFirstDay] = useState('');
    const [lastDay, setLastDay] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [recordedit, setRecordEdit] = useState(null);
    const [idViaje, setIdViaje] = useState(null);

    const [chekingCSD, setCheckinCSD] = useState(true);


    const [facturaArchivos, setFacturaArchivos] = useState(null);

    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.grey[500],
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.common.white,
                    borderColor: theme.palette.grey[500],
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);

    useEffect(() => {
        setIsOpen(props.isOpen);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {

        }
    }, []);

    useEffect(() => {

    }, [user]);





    const initValues = async () => {
        setIsLoading(false);
    }

    useEffect(() => {


    }, [recordedit]);


    useEffect(() => {


        setUid(props.uid);
        setUidAltaEdit(props.uidAltaEdit);
        setIsAdmin(props.isAdmin);
        setAdminCurrentUser(props.adminCurrentUser);

        //  initValues();

        setIsLoading(false);
        setIsOpen(props.isOpen);
        setIsEdit(props.isEdit);
        // setRecordEdit(props.currenteditrecrod);

    }, [props]);






    useEffect(() => {
        if (!isOpen) {
            props.toggle();
        } else {

            if (isEdit && recordedit) {
                // setTipo(recordedit.tipo);

            } else {
                // setTipo('Costo Fijo');

                let datetoset = new Date();
                if (lastDay.getTime() >= datetoset.getTime()) {
                    setFecha(new Date());
                } else {
                    setFecha(props.currentDate);
                }
            }
        }
    }, [isOpen]);

    const handleSumbit = async (e) => {
        e.preventDefault();

    }

    return <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} size="lg">
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Registrando datos, espere ...'
        >
            <ModalHeader toggle={() => setIsOpen(false)}>
                {facturaArchivos ? "Descargar Nota Crédito" : `Facturar Nota de Crédito `}
            </ModalHeader>
            <form onSubmit={handleSumbit}>
                <ModalBody>
                    <div>
                        <div className="row">
                            Hola mundo

                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>

                    <button type="button" className="boton redBtn erase" style={{ backgroundColor: "#fcf0f0" }} onClick={() => setIsOpen(false)} >Salir</button>
                    <button type="submit" className="boton secBtn"> Aceptar</button>
                </ModalFooter>
            </form>
        </LoadingOverlay>
    </Modal >
}
export default ModalCreateEdit;