import React, { useState, useEffect, Fragment } from 'react';
import ModalConfiguracioens from '../ModalConfiguraciones';
import ModalDolarPrice from '../ModalDolarPrice';
import { firebase } from '../../../configs/firebase';
import SettingsIcon from '@material-ui/icons/Settings';
import { getUserByUid, getUserDashboardByUid, addUserDashboard, updateUserDashboard } from '../../../controllers/usuarios';
import { getViajeRepo } from '../../../controllers/viajes';
import { getAllViajesTimbradosByUserDateFilter, getAllViajesByUserAndClienteDateFilter } from '../../../controllers/viajes';
import { getInvoicesDateFilter, getCfdiSolos, getCfdiSolosByCliente } from '../../../controllers/innvoices';
import { getUnidades } from '../../../controllers/unidades';
import { getUidMaster } from '../../../session/session_controller';
import { getResumenUser } from '../../../controllers/resumen';
import Configuraciones from '../Configuraciones';
import { getAllUsuarios } from '../../../controllers/usuarios';
import Swal from 'sweetalert2';
import DatePicker from 'react-datepicker';
import { addDays } from "date-fns";
import Typography from '@mui/material/Typography';
import InfoSegmento from './InfoSegmento';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { width } from '@mui/system';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment';
import xml2js from 'xml2js';

const DashboardContent = (props) => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [userlist, setUserlist] = useState([]);
    const [fecha, setFecha] = useState(new Date());
    const [firstDay, setFirstDay] = useState(null);
    const [lastDay, setLastDay] = useState(null);
    const [user, setUser] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenDolarPrice, setIsOpenDolarPrice] = useState(false);
    const [isGerencia, setIsGerencia] = useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState(true);//EKU9003173C9
    const [segmentosNegocio, setSegmentosNegocio] = useState(['Tracto']);
    const [timbresRestantes, setTimbresRestantes] = useState(0);
    const [uid, setUid] = useState(null);
    const [dataHistorica, setDataHistorica] = useState([]);

    const [loadinHistograma, setLoagingHistograma] = useState(false);

    const [uidAltaEdit, setUidAltaEdit] = useState(null);

    const [totalIngresos, setTotalIngresos] = useState(0);
    const [totalIngresosDLL, setTotalIngresosDLL] = useState(0);
    const [idDataDashboard, setIdDataDashboard] = useState(null);
    const [unidadesActivas, setUnidadesActivas] = useState(0);
    const [unidadesSinOperador, setUnidadesSinOperador] = useState(0);
    const [unidadesEnTaller, setUnidadesEnTaller] = useState(0);

    const [yearconsulta, setYearConsulta] = useState(new Date());



    const formatoMoneda = (valor) => {
        let fixtostring = "" + valor;
        let fixtofloat = parseFloat(fixtostring);
        let toMoney = '$' + fixtofloat.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        return toMoney;

    }


    const validateAdmin = async (uid) => {
        await getUserByUid(uid).then(snapshot => {
            let isadmin = false;
            snapshot.forEach(doc => {
                let user = doc.data();
                if (user.type && user.type === "administrador") {
                    isadmin = true;
                    setIsGerencia(true);
                }
            });
            if (isadmin) {
                setAdminCurrentUser(uid);
                setIsAdmin(true);
            } else {
                localStorage.setItem('marketpointmanager-user', 'user');
                setIsAdmin(false);
                setUserlist([]);
                // this.setState({ isAdmin: isadmin, userlist: [] });
            }

        }).catch();
    }

    useEffect(() => {
        if (yearconsulta) {
            getData();
        }
    }, [yearconsulta])
    const getHistorico = async (mes, year) => {
        let fixmes = mes;
        if (mes < 10) {
            fixmes = '0' + fixmes;
        }
        console.log("getting historico", `${fixmes}-${year}`);
        let objresponse = null;
        await getResumenUser(uid, `${fixmes}-${year}`).then(resp => {
            if (resp.docs.length > 0) {

                resp.forEach(doc => {
                    objresponse = doc.data();
                    objresponse.id = doc.id;
                });
                console.log("hay historico en ", `${fixmes}-${year}`);
                console.log("id historico ", `${objresponse.id}`);
            }
        });
        return objresponse;
    }
    const getClienteInfoTotalFromXML = async (xmldata) => {
        // console.log("data xml",xmldata);

        let total = "";
        await xml2js.parseString(xmldata, async (err, result) => {
            if (err) {
                console.log(err);
            }
            else {
                // console.log("dataxml", result["tfd:TimbreFiscalDigital"]["$"]);
                //  let objdataxml = result["tfd:TimbreFiscalDigital"]["$"];
                // console.log("primer xml", result['cfdi:Comprobante']["$"]["Total"]);
                total = result['cfdi:Comprobante']["$"]["Total"];
                //nombreCliente=result['cfdi:Comprobante']['cfdi:Receptor'][0]['$']['Nombre'];
                // response = result["tfd:TimbreFiscalDigital"]["$"]["NoCertificadoSAT"];
            }

        });
        return total;
    }
    const procesarPrimeraConsultaDelMes = async (uiduser) => {

        let idConsulta = uiduser;

        console.log("consultando");
        //  setIsLoading(true);

        let fDay = new Date(fecha.getFullYear(), fecha.getMonth(), 1);
        let lDay = new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0);
        console.log("last day mes", lDay);
        console.log("last day", lDay.getDate())

        let diadiez = new Date(fDay.getFullYear(), fDay.getMonth(), fDay.getDate() + 10);
        let diaveinte = new Date(fDay.getFullYear(), fDay.getMonth(), fDay.getDate() + 20);

        let diasfor = [3, 6, 9, 12, 15, 18, 21, 24, 27, 30];
        let inicioaux = 1;
        let listaIDS = [];
        let prog = 0;

        const procesarRespuesta = (snapshot, isCFDI) => {
            snapshot.forEach(async (doc) => {
                let pendientes = 0.0;
                let o = doc.data();
                let odt = {};
                if (!o.facuturado) {
                    return;
                }

                odt.id = doc.id;
                odt.key = doc.id;
                if (isCFDI) {
                    odt.nfactura = `F-${o.folioFactura}`;
                    odt.carta_porte = "";
                    odt.cliente = o.cliente;
                    odt.referencia = o.referencia || "";
                    odt.fecha = o.fecha_facturado || null;
                    odt.fechaformat = o.fecha_facturado ? moment(o.fecha_facturado.toDate()).format("DD-MM-YYYY") : "";
                    odt.fechaformat2 = o.fecha_facturado ? moment(o.fecha_facturado.toDate()).format("DD/MM/YYYY") : "";
                    odt.total = parseFloat(o.total) || 0.00;
                    odt.factura_monto = odt.total;
                    odt.moneda = o.moneda || "";
                    odt.distBase_km = "";
                    odt.distVacio_km = "";
                    odt.distTotales_km = "";
                    odt.cancelada = o.facturaCancelada || false;
                } else {
                    odt.factura = o.general.nviaje;
                    odt.carta_porte = o.general.ncarta;
                    odt.segmentoNegocio = o.segmentoNegocio;
                    odt.tipoviaje = o.datos_viaje.tipo
                    odt.cancelada = o.facturaCancelada || false;
                    odt.cliente = o.general.cliente;
                    odt.subcliente = o.general.subcliente || "";
                    odt.clienteId = o.general.clienteId;
                    odt.referencia = o.general.referencia;
                    odt.total = o.detalles.tarifaFloat;
                    odt.moneda = o.detalles.currencyMX ? "MXN" : "USD";
                    odt.fecha = o.fecha_facturado || null;
                    odt.fechaformat = o.fecha_facturado ? moment(o.fecha_facturado.toDate()).format("DD-MM-YYYY") : "";
                    odt.fechaformat2 = o.fecha_facturado ? moment(o.fecha_facturado.toDate()).format("DD/MM/YYYY") : "";
                    odt.nfactura = o.folioFactura ? `F-${o.folioFactura}` : "";

                    odt.distBase_km = o.carta_porte.distBase_km;
                    odt.distVacio_km = o.carta_porte.distVacio_km;
                    odt.distTotales_km = o.carta_porte.distTotales_km;


                }


                if (o.facuturado) {
                    //odt.cliente = await getClienteInfoFromXML(o.facuturado.cfdixml);
                    //odt.rfcTimbrado = await getRfcTimbradoFromXML(o.facuturado.cfdixml);
                    if (!o.total || o.total === "") {
                        //console.log("test");
                        //  o.total= await getClienteInfoTotalFromXML(o.facuturado.cfdixml)
                        let totalf = await getClienteInfoTotalFromXML(o.facuturado.cfdixml);
                        totalf = parseFloat(totalf);
                        odt.factura_monto = totalf;
                    }
                }

                if (o.pagos) {
                    //calculamos pendientes
                    let pagado = 0.0;
                    o.pagos.forEach(element => {
                        pagado += element.montoFloat;
                    });

                    if (o.factura_monto >= pagado) {
                        pendientes = o.factura_monto - pagado;
                    } else {
                        pendientes = 0.0;
                    }

                } else {
                    pendientes = o.factura_monto;
                }
                odt.pendientes = pendientes;
                odt.obj = { ...odt };

                odt.operador = "";
                odt.unidad = "";
                if (o.dataTimbrada) {
                    if (o.dataTimbrada.transportista) {
                        odt.operador = o.dataTimbrada.transportista.Nombre;
                    }

                    odt.unidad = o.dataTimbrada.NumeroEconomico;
                } else if (o.isFormatoNuevo === false) {
                    odt.operador = o.detalles.operador1;
                    if (o.detalles.operador1 === "N/A" && o.detalles.operador2 !== "N/A") {
                        odt.operador = o.detalles.operador2;
                    }
                    odt.unidad = o.detalles.unidad;
                }
                odt.origen = "";
                odt.destino = "";
                if (o.datos_viaje) {
                    odt.origen = o.datos_viaje.origen;
                    odt.destino = o.datos_viaje.destino;
                }
                odt.status = o.status;
                if (odt.cancelada || odt.facturaCancelada) {
                    odt.status = "Cancelado";
                    odt.cancelada = true;
                }
                odt.facuturado = o.facuturado ? { uuid: o.facuturado.uuid, cfdixml: o.facuturado.cfdixml } : null;
                odt.facturaname = `${o.prefijoFactura}-${o.folioFactura}`;

                listaIDS.push(odt);
            });
        }
        for await (let a of diasfor) {
            prog += 9;
            //setProgreso(prog);
            fDay = new Date(fecha.getFullYear(), fecha.getMonth(), inicioaux);
            if (a === 30) {
                diadiez = new Date(fDay.getFullYear(), fDay.getMonth(), lDay.getDate() + 1);
                inicioaux = a + 1;
            } else {
                diadiez = new Date(fDay.getFullYear(), fDay.getMonth(), fDay.getDate() + 2);
                inicioaux = a + 1;
            }

            //periodo
            let inicio = new Date(fDay);
            inicio.setHours(0, 0, 0);
            let fin = new Date(diadiez);
            fin.setHours(23, 59, 59);
            // console.log("periodo inicio",inicio);
            // console.log("periodo fin",fin);


            await getAllViajesTimbradosByUserDateFilter(idConsulta, inicio, fin).then(snapshot => {
                procesarRespuesta(snapshot, false);
            });


            await getCfdiSolos(idConsulta, inicio, fin).then(snapshot => {
                console.log("respuesta de cfdisolos", snapshot.docs.length);
                procesarRespuesta(snapshot, true);
            });



        }

        const auxfacturas = [];
        let totalbuscado = 0;
        let totalbuscadoDLL = 0;
        let recientefecha = null;
        await listaIDS.forEach(async (element) => {
            let foliouuid = "";
            let subtotal = "";
            let totalcfdi = "";
            let monedacfdi = "";
            let trasladado = "";
            let retenido = "";
            if (!recientefecha) {
                recientefecha = element.fecha.toDate();
            } else {
                let aux1fecha = moment(element.fecha.toDate());
                let aux2fecha = moment(recientefecha);
                if (aux1fecha > aux2fecha) {
                    //console.log("actualizando fecha reciente",aux1fecha);
                    recientefecha = aux1fecha.toDate();
                }
            }
            if (element.facuturado) {
                foliouuid = element.facuturado.uuid;

                await xml2js.parseString(element.facuturado.cfdixml, async (err, result) => {
                    if (err) {
                        console.log(err);
                    }
                    else {
                        // console.log("dataxml", result["tfd:TimbreFiscalDigital"]["$"]);
                        //  let objdataxml = result["tfd:TimbreFiscalDigital"]["$"];
                        // console.log("primer xml", result['cfdi:Comprobante']["$"]["Total"]);
                        subtotal = result['cfdi:Comprobante']["$"]["SubTotal"];
                        totalcfdi = result['cfdi:Comprobante']["$"]["Total"];
                        monedacfdi = result['cfdi:Comprobante']["$"]["Moneda"];
                        monedacfdi === "MXN" ? totalbuscado += parseFloat(parseFloat(totalcfdi).toFixed(2)) : totalbuscadoDLL += parseFloat(parseFloat(totalcfdi).toFixed(2));;


                        // console.log("impuestos retenidos", result['cfdi:Comprobante']['cfdi:Impuestos'])
                        retenido = result['cfdi:Comprobante']['cfdi:Impuestos'] ? result['cfdi:Comprobante']['cfdi:Impuestos'][0]['$']['TotalImpuestosRetenidos'] || "" : "";
                        trasladado = result['cfdi:Comprobante']['cfdi:Impuestos'] ? result['cfdi:Comprobante']['cfdi:Impuestos'][0]['$']['TotalImpuestosTrasladados'] || "" : "";
                        // trasladado = result['cfdi:Comprobante']['cfdi:Impuestos']['$']['cfdi:Traslados'][0]['cfdi:Traslado'][0]['$']['Importe'] || "";

                    }

                });
            }
            let n = { ...element, foliouuid, subtotal, totalcfdi, monedacfdi, retenido, trasladado };

            auxfacturas.push(n);
        });
        console.log("total de lo buscado====", totalbuscado);
        console.log("fecha ====", moment(recientefecha).format('DD-MM-YYYY hh:mm:ss'));
        const fechaactual = new Date();
        const fechaparsed = moment(fechaactual).format("MM-YYYY");
        let datadashboard = {
            uid,
            fecha: fechaparsed,
            lastFacturaFecha: recientefecha ? recientefecha : new Date(),
            total: totalbuscado,
            totalDLL: totalbuscadoDLL,
            cancelados: []
        }
        addUserDashboard(datadashboard).then(resp => { }).catch(err => { console.log("error al guardar dashboard") });
        setTotalIngresos(totalbuscado);
        setTotalIngresosDLL(totalbuscadoDLL);

    }
    const busquedaPosterior = async (uiduser, data, idData) => {
        let idConsulta = uiduser;

        console.log("consultando");
        //  setIsLoading(true);

        let fDay = new Date(fecha.getFullYear(), fecha.getMonth(), 1);
        let lDay = new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0);
        console.log("last day mes", lDay);
        console.log("last day", lDay.getDate())

        let diadiez = new Date(fDay.getFullYear(), fDay.getMonth(), fDay.getDate() + 10);
        let diaveinte = new Date(fDay.getFullYear(), fDay.getMonth(), fDay.getDate() + 20);


        let inicioaux = 1;
        let listaIDS = [];

        const procesarRespuesta = (snapshot, isCFDI) => {
            snapshot.forEach(async (doc) => {
                let pendientes = 0.0;
                let o = doc.data();
                let odt = {};
                if (!o.facuturado) {
                    return;
                }

                odt.id = doc.id;
                odt.key = doc.id;
                if (isCFDI) {
                    odt.nfactura = `F-${o.folioFactura}`;
                    odt.carta_porte = "";
                    odt.cliente = o.cliente;
                    odt.referencia = o.referencia || "";
                    odt.fecha = o.fecha_facturado || null;
                    odt.fechaformat = o.fecha_facturado ? moment(o.fecha_facturado.toDate()).format("DD-MM-YYYY") : "";
                    odt.fechaformat2 = o.fecha_facturado ? moment(o.fecha_facturado.toDate()).format("DD/MM/YYYY") : "";
                    odt.total = parseFloat(o.total) || 0.00;
                    odt.factura_monto = odt.total;
                    odt.moneda = o.moneda || "";
                    odt.distBase_km = "";
                    odt.distVacio_km = "";
                    odt.distTotales_km = "";
                    odt.cancelada = o.facturaCancelada || false;
                } else {
                    odt.factura = o.general.nviaje;
                    odt.carta_porte = o.general.ncarta;
                    odt.segmentoNegocio = o.segmentoNegocio;
                    odt.tipoviaje = o.datos_viaje.tipo
                    odt.cancelada = o.facturaCancelada || false;
                    odt.cliente = o.general.cliente;
                    odt.subcliente = o.general.subcliente || "";
                    odt.clienteId = o.general.clienteId;
                    odt.referencia = o.general.referencia;
                    odt.total = o.detalles.tarifaFloat;
                    odt.moneda = o.detalles.currencyMX ? "MXN" : "USD";
                    odt.fecha = o.fecha_facturado || null;
                    odt.fechaformat = o.fecha_facturado ? moment(o.fecha_facturado.toDate()).format("DD-MM-YYYY") : "";
                    odt.fechaformat2 = o.fecha_facturado ? moment(o.fecha_facturado.toDate()).format("DD/MM/YYYY") : "";
                    odt.nfactura = o.folioFactura ? `F-${o.folioFactura}` : "";

                    odt.distBase_km = o.carta_porte.distBase_km;
                    odt.distVacio_km = o.carta_porte.distVacio_km;
                    odt.distTotales_km = o.carta_porte.distTotales_km;


                }


                if (o.facuturado) {
                    //odt.cliente = await getClienteInfoFromXML(o.facuturado.cfdixml);
                    //odt.rfcTimbrado = await getRfcTimbradoFromXML(o.facuturado.cfdixml);
                    if (!o.total || o.total === "") {
                        //console.log("test");
                        //  o.total= await getClienteInfoTotalFromXML(o.facuturado.cfdixml)
                        let totalf = await getClienteInfoTotalFromXML(o.facuturado.cfdixml);
                        totalf = parseFloat(totalf);
                        odt.factura_monto = totalf;
                    }
                }

                if (o.pagos) {
                    //calculamos pendientes
                    let pagado = 0.0;
                    o.pagos.forEach(element => {
                        pagado += element.montoFloat;
                    });

                    if (o.factura_monto >= pagado) {
                        pendientes = o.factura_monto - pagado;
                    } else {
                        pendientes = 0.0;
                    }

                } else {
                    pendientes = o.factura_monto;
                }
                odt.pendientes = pendientes;
                odt.obj = { ...odt };

                odt.operador = "";
                odt.unidad = "";
                if (o.dataTimbrada) {
                    if (o.dataTimbrada.transportista) {
                        odt.operador = o.dataTimbrada.transportista.Nombre;
                    }

                    odt.unidad = o.dataTimbrada.NumeroEconomico;
                } else if (o.isFormatoNuevo === false) {
                    odt.operador = o.detalles.operador1;
                    if (o.detalles.operador1 === "N/A" && o.detalles.operador2 !== "N/A") {
                        odt.operador = o.detalles.operador2;
                    }
                    odt.unidad = o.detalles.unidad;
                }
                odt.origen = "";
                odt.destino = "";
                if (o.datos_viaje) {
                    odt.origen = o.datos_viaje.origen;
                    odt.destino = o.datos_viaje.destino;
                }
                odt.status = o.status;
                if (odt.cancelada || odt.facturaCancelada) {
                    odt.status = "Cancelado";
                    odt.cancelada = true;
                }
                odt.facuturado = o.facuturado ? { uuid: o.facuturado.uuid, cfdixml: o.facuturado.cfdixml } : null;
                odt.facturaname = `${o.prefijoFactura}-${o.folioFactura}`;

                listaIDS.push(odt);
            });
        }

        //setProgreso(prog);
        fDay = data.lastFacturaFecha.toDate();
        const addSeconds = (date, seconds) => {
            date.setSeconds(date.getSeconds() + seconds);
            return date;
        }
        const fixFday = addSeconds(fDay, 1);
        //periodo
        let inicio = new Date(fixFday);
        // inicio.setHours(0, 0, 0);
        let fin = new Date(lDay);
        fin.setHours(23, 59, 59);
        // console.log("periodo inicio",inicio);
        // console.log("periodo fin",fin);


        await getAllViajesTimbradosByUserDateFilter(idConsulta, inicio, fin).then(snapshot => {
            procesarRespuesta(snapshot, false);
        });


        await getCfdiSolos(idConsulta, inicio, fin).then(snapshot => {
            console.log("respuesta de cfdisolos", snapshot.docs.length);
            procesarRespuesta(snapshot, true);
        });





        const auxfacturas = [];
        let totalbuscado = data.total;
        let totalbuscadoDLL = data.totalDLL || 0;
        let recientefecha = null;
        await listaIDS.forEach(async (element) => {
            let foliouuid = "";
            let subtotal = "";
            let totalcfdi = "";
            let monedacfdi = "";
            let trasladado = "";
            let retenido = "";
            if (!recientefecha) {
                recientefecha = element.fecha.toDate();
            } else {
                let aux1fecha = moment(element.fecha.toDate());
                let aux2fecha = moment(recientefecha);
                if (aux1fecha > aux2fecha) {
                    //console.log("actualizando fecha reciente",aux1fecha);
                    recientefecha = aux1fecha.toDate();
                }
            }
            if (element.facuturado) {
                foliouuid = element.facuturado.uuid;

                await xml2js.parseString(element.facuturado.cfdixml, async (err, result) => {
                    if (err) {
                        console.log(err);
                    }
                    else {
                        // console.log("dataxml", result["tfd:TimbreFiscalDigital"]["$"]);
                        //  let objdataxml = result["tfd:TimbreFiscalDigital"]["$"];
                        // console.log("primer xml", result['cfdi:Comprobante']["$"]["Total"]);
                        subtotal = result['cfdi:Comprobante']["$"]["SubTotal"];
                        totalcfdi = result['cfdi:Comprobante']["$"]["Total"];
                        monedacfdi = result['cfdi:Comprobante']["$"]["Moneda"];
                        monedacfdi === "MXN" ? totalbuscado += parseFloat(parseFloat(totalcfdi).toFixed(2)) : totalbuscadoDLL += parseFloat(parseFloat(totalcfdi).toFixed(2));;


                        // console.log("impuestos retenidos", result['cfdi:Comprobante']['cfdi:Impuestos'])
                        retenido = result['cfdi:Comprobante']['cfdi:Impuestos'] ? result['cfdi:Comprobante']['cfdi:Impuestos'][0]['$']['TotalImpuestosRetenidos'] || "" : "";
                        trasladado = result['cfdi:Comprobante']['cfdi:Impuestos'] ? result['cfdi:Comprobante']['cfdi:Impuestos'][0]['$']['TotalImpuestosTrasladados'] || "" : "";
                        // trasladado = result['cfdi:Comprobante']['cfdi:Impuestos']['$']['cfdi:Traslados'][0]['cfdi:Traslado'][0]['$']['Importe'] || "";

                    }

                });
            }
            let n = { ...element, foliouuid, subtotal, totalcfdi, monedacfdi, retenido, trasladado };

            auxfacturas.push(n);
        });
        console.log("total de lo buscado====", totalbuscado);
        console.log("fecha ====", moment(recientefecha).format('DD-MM-YYYY hh:mm:ss'));
        const fechaactual = new Date();
        const fechaparsed = moment(fechaactual).format("MM-YYYY");
        let datadashboard = {
            uid,
            fecha: fechaparsed,
            lastFacturaFecha: recientefecha ? recientefecha : new Date(),
            total: totalbuscado,
            totalDLL: totalbuscadoDLL,
            cancelados: []
        }
        if (!recientefecha) {
            datadashboard = {
                uid,
                fecha: fechaparsed,
                total: totalbuscado,
                totalDLL: totalbuscadoDLL,
                cancelados: []
            }
        }

        if (idData && idData !== "") {
            updateUserDashboard(idData, datadashboard).then(resp => { }).catch(err => { console.log("error al actualizar dashboard") });
        } else {
            console.log("no ID data dashboard");
        }

        setTotalIngresos(totalbuscado);
        setTotalIngresosDLL(totalbuscadoDLL);



    }
    const consultarSaldos = (uiduser) => {
        const fechaactual = new Date();
        const fechaparsed = moment(fechaactual).format("MM-YYYY");
        //console.log("consultar dash",fechaparsed);
        getUserDashboardByUid(uiduser, fechaparsed).then(resp => {
            if (resp.docs.length === 0) {
                //primera consulta del mes
                procesarPrimeraConsultaDelMes(uiduser);
            } else {
                //consulta posterior
                setIdDataDashboard(resp.docs[0].id);
                let datadash = resp.docs[0].data();
                busquedaPosterior(uiduser, datadash, resp.docs[0].id);

            }
        });
    }
    const consultaUnidades = async (uiduser) => {
        let totalUnidadesActivas = 0;
        let totalUnidadesMantenimiento = 0;
        let totalUndiadesSinOperador = 0;
        await getUnidades(uiduser).then(resp => {
            resp.forEach(doc => {
                let od = doc.data();
                if (od.active) {
                    totalUnidadesActivas++;
                }

                if (od.estado && od.estado === "Mantenimiento") {
                    totalUnidadesMantenimiento++;
                }
                if (!od.operador) {
                    totalUndiadesSinOperador++;
                }
            })
        });
        setUnidadesActivas(totalUnidadesActivas);
        setUnidadesEnTaller(totalUnidadesMantenimiento);
        setUnidadesSinOperador(totalUndiadesSinOperador);

    }
    const getData = async () => {
        if (uid) {
            consultarSaldos(uid);
            consultaUnidades(uid);
            await getUserByUid(uid).then(resp => {
                resp.forEach(doc => {
                    let userobj = doc.data();
                    let auxsegmentos = userobj.segmento_negocio.map(s => {
                        return s.value;
                    })
                    console.log("segmentos de negocio", auxsegmentos);
                    setSegmentosNegocio(auxsegmentos);
                })
            });

            await getViajeRepo(uid).then(resp => {
                let nt = 0;
                resp.forEach(document => {
                    let o = document.data();
                    if (o && o.ntimbres && nt <= o.ntimbres) {
                        nt = o.ntimbres;
                    }
                });
                setTimbresRestantes(nt);
            }).catch(err => { });

            //let fechaactual = new Date();
            const year = yearconsulta.getFullYear();
            let meses = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
            let historicos = [];
            setLoagingHistograma(true);
            for await (let a of meses) {
                let respuesta = await getHistorico(a, year);
                if (respuesta) {
                    historicos.push(respuesta);
                }
            }
            setLoagingHistograma(false);
            setDataHistorica(historicos);
            
            console.log("fin consulta historico");
            console.log(dataHistorica);
        }
    }
    useEffect(() => {
        if (isAdmin) {
            getAllUsuarios().then(snapshot => {
                let index = 1;
                let a = [];
                snapshot.forEach(doc => {
                    let o = doc.data();
                    o.index = doc.id;
                    o.n = index;
                    index++;
                    a.push(o);
                });
                setUserlist(a);
            }).catch(e => {
                console.log(e);
                //this.setState({isLoading:false,})
            });
        }
    }, [isAdmin]);

    useEffect(() => {


        let fDay = new Date(fecha.getFullYear(), fecha.getMonth(), 1);
        let lDay = new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0);
        setFirstDay(fDay);
        setLastDay(lDay);

        firebase.auth().onAuthStateChanged((userr) => {
            if (userr) {
                setUidAltaEdit(userr.uid);
                getUidMaster() ? setUid(getUidMaster()) :
                    setUid(userr.uid);
                setUser(userr);
                // setAdminCurrentUser(userr.uid);
                if (localStorage.getItem('allow')) {
                    let allows = localStorage.getItem('allow').split(":");
                    let permiso = allows.find(p => p === 'dashboard');
                    if (permiso) {
                        setIsGerencia(true);
                    }
                }

                if (localStorage.getItem('marketpointmanager-user') && localStorage.getItem('marketpointmanager-user') === 'administrador') {
                    // this.validateAdmin(user.uid);
                    //console.log("validando admin");
                    validateAdmin(userr.uid);
                } else if (localStorage.getItem('marketpointmanager-user') && (localStorage.getItem('marketpointmanager-user') === 'gerencia' || localStorage.getItem('marketpointmanager-user') === 'administrador')) {
                    setIsGerencia(true);
                }
            } else {
                // No user is signed in.
                localStorage.removeItem('marketpointmanagerid');
                localStorage.removeItem('marketpointmanager-user');
                window.location.href = '/';
            }
        });

    }, []);
    useEffect(() => {
        if (localStorage.getItem('allow')) {
            let allows = localStorage.getItem('allow').split(":");
            let permiso = allows.find(p => p === 'dashboard');
            if (permiso) {
                getData();
            }
        }

    }, [uid]);

    const authgerencial = async () => {
        console.log("here");
        await getUserByUid(user.uid).then(snapshot => {
            let isadmin = false;
            console.log("here 2");
            snapshot.forEach(doc => {
                console.log("here 3");
                let user = doc.data();
                if (user.gerencia_password) {

                    Swal.fire({
                        title: 'Acceder a nivel gerencial',
                        html: `
                        <input type="password" id="password" className="swal2-input" placeholder="Password">`,
                        confirmButtonText: 'Acceder',
                        focusConfirm: false,
                        preConfirm: () => {

                            const password = Swal.getPopup().querySelector('#password').value
                            if (!password) {
                                Swal.showValidationMessage(`Ingresa la contraseña para nivel gerencial`)
                            }
                            return { password: password }
                        }
                    }).then((result) => {

                        if (result && result.value && result.value.password === user.gerencia_password) {
                            localStorage.setItem('marketpointmanager-user', "gerencia");
                            window.location.href = '/dashboard';
                        } else if (result && result.value) {
                            Swal.fire("Error", "Contraseña de nivel gerencial incorrecta", 'error');
                        }
                    })


                } else {
                    console.log("no gerencia password");
                }
            });

        }).catch(err => {
            console.log("err", err);
        });
        //alert(user);
    }


    return (
        <Fragment>
            <LoadingOverlay
                active={loadinHistograma}
                spinner
                text='Consultando datos, espere ...'
            >
                {
                    <div className="row" >
                        <div className="col-12">
                            <h3 style={{ marginLeft: '10px', marginTop: '10px', fontWeight: 'bold' }}>&nbsp;Dashboard</h3>
                            <div className="card">


                                <div className="card-body">

                                    <div className="air__layout__content p-5">
                                        <div className="air__utils__content">
                                            <div className="row card-panels">



                                                {isGerencia && <div style={{ display: 'flex', width: '90%', flexDirection: 'row' }}>
                                                    <div className="dataTable" style={{ width: '17.5%', marginLeft: '2%' }}>
                                                        <div className="dataTitle" style={{ height: "50px" }}><p className="fixedP" style={{ width: '100%', padding: 'auto', textAlign: 'center' }}> Total de Ingresos</p></div>
                                                        <div className="dataBox" style={{ height: "50px" }}>
                                                            <div style={{ width: '100%', height: '100%', position: 'relative', textAlign: 'center' }}>
                                                                <p className='dashboarditem-content-vertical-center'>
                                                                    {formatoMoneda(totalIngresos)}

                                                                </p>

                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className="dataTable" style={{ width: '17.5%', marginLeft: '2%' }}>
                                                        <div className="dataTitle" style={{ height: "50px" }}><p className="fixedP" style={{ width: '100%', padding: 'auto', textAlign: 'center' }}> Facturación Vencida</p></div>
                                                        <div className="dataBox" style={{ height: "50px" }}>
                                                            <div style={{ width: '100%', height: '100%', position: 'relative', textAlign: 'center' }}>
                                                                <p className='dashboarditem-content-vertical-center-danger'>$0.00</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="dataTable" style={{ width: '17.5%', marginLeft: '2%' }}>
                                                        <div className="dataTitle" style={{ height: "50px" }}><p className="fixedP" style={{ width: '100%', padding: 'auto', textAlign: 'center' }}> Unidades en Activo</p></div>
                                                        <div className="dataBox" style={{ height: "50px" }}>
                                                            <div style={{ width: '100%', height: '100%', position: 'relative', textAlign: 'center' }}>
                                                                <p className='dashboarditem-content-vertical-center'>{unidadesActivas}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="dataTable" style={{ width: '17.5%', marginLeft: '2%' }}>
                                                        <div className="dataTitle" style={{ height: "50px" }}><p className="fixedP" style={{ width: '100%', padding: 'auto', textAlign: 'center' }}> Unidades Sin Operador</p></div>
                                                        <div className="dataBox" style={{ height: "50px" }}>
                                                            <div style={{ width: '100%', height: '100%', position: 'relative', textAlign: 'center' }}>
                                                                <p className='dashboarditem-content-vertical-center'>{unidadesSinOperador}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="dataTable" style={{ width: '17.5%', marginLeft: '2%' }}>
                                                        <div className="dataTitle" style={{ height: "50px" }}><p className="fixedP" style={{ width: '100%', padding: 'auto', textAlign: 'center' }}> Unidades en Taller</p></div>
                                                        <div className="dataBox" style={{ height: "50px" }}>
                                                            <div style={{ width: '100%', height: '100%', position: 'relative', textAlign: 'center' }}>
                                                                <p className='dashboarditem-content-vertical-center'>{unidadesEnTaller}</p>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>}
                                                <div style={{ width: "80%" }}>
                                                    <div style={{ width: "100px", marginBottom: "5px", marginLeft: "35px" }}>
                                                        <DatePicker
                                                            className="form-control customdatepicker"
                                                            locale="es"
                                                            selected={yearconsulta}
                                                            onChange={date => {
                                                                setYearConsulta(date);
                                                            }}
                                                            maxDate={addDays(new Date(), 1)}
                                                            dateFormat="yyyy"
                                                            showYearPicker
                                                        />
                                                    </div>
                                                </div>
                                                {isGerencia && segmentosNegocio.length > 1 && <div style={{ width: '88%' }} ><Carousel plugins={['arrows']}>
                                                    {segmentosNegocio.map(s => {
                                                        return <InfoSegmento key={`s-${s}`} segmento={s} dataHistorica={dataHistorica} />
                                                    })} 
                                                </Carousel></div>
                                                }
                                                {
                                                    isGerencia && segmentosNegocio.length == 1 && <div style={{ width: '88%', marginLeft: '30px' }}>
                                                        <InfoSegmento segmento={segmentosNegocio[0]} dataHistorica={dataHistorica} />
                                                    </div>
                                                }
                                                <hr />
                                                {/*
                                            <h3>Rabón</h3>
                                            <div className="col-12">
                                                <Grid container style={{ flex: 1 }} justify="center" spacing={2} >
                                                    <Grid style={{
                                                        width: '33%',
                                                        display: 'flex'
                                                    }} key={1} item>
                                                        <Paper style={{
                                                            minHeight: 80,
                                                            height: 'auto',
                                                            width: '100%',
                                                            backgroundColor: '#f0f8ff'
                                                        }} elevation={3} >
                                                           <div className="col-12">
                                                               Ingresos
                                                           </div>
                                                        </Paper>
                                                    </Grid>
                                                    <Grid style={{
                                                        width: '33%',
                                                        display: 'flex'
                                                    }} key={1} item>
                                                        <Paper style={{
                                                            minHeight: 80,
                                                            height: 'auto',
                                                            width: '100%',
                                                            backgroundColor: '#f0f8ff'
                                                        }} elevation={3} >
                                                            <div className="col-12">
                                                               Costos
                                                           </div>
                                                        </Paper>
                                                    </Grid>
                                                    <Grid style={{
                                                        width: '33%',
                                                        display: 'flex'
                                                    }} key={1} item>
                                                        <Paper style={{
                                                            minHeight: 80,
                                                            height: 'auto',
                                                            width: '100%',
                                                            backgroundColor: '#f0f8ff'
                                                        }} elevation={3} >
                                                            <div className="col-12">
                                                               Utilidad
                                                           </div>
                                                        </Paper>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <hr />
                                            <div style={{marginTop:"10px",marginBottom:"10px"}} className="col-12">
                                                <Grid container style={{ flex: 1 }} justify="center" spacing={2} >
                                                    <Grid style={{
                                                        width: '100%',
                                                        display: 'flex'
                                                    }} key={1} item>
                                                        <Paper style={{
                                                            minHeight: 80,
                                                            height: 'auto',
                                                            width: '100%',
                                                            backgroundColor: '#f0f8ff'
                                                        }} elevation={3} >
                                                            <Crazy />
                                                        </Paper>
                                                    </Grid>
                                                   
                                                </Grid>
                                            </div>*/}


                                                {/*         Aqui se podria poner un Loader  */}
                                                {/*!isGerencia && <div className="btn btn-primary" onClick={() => authgerencial()}>
                                                <VpnKeyIcon size="default" style={{
                                                    color: "#9e9e9e",

                                                }} aria-label="editar" />
                                                Acceder a modo gerencial
                                            </div>*/}

                                                {
                                                    user && isGerencia && <div style={{ width: '90%' }}>
                                                        <div style={{ display: 'flex', width: '90%', flexDirection: 'row' }}>
                                                            <div className="dataTable" style={{ width: '32.5%', marginLeft: '2%' }}>
                                                                <div className="dataTitle" style={{ height: "50px" }}><p className="fixedP" style={{ width: '100%' }}> Alertas</p></div>
                                                                <div className="dataBox" style={{ height: "auto", padding: '12px' }}>
                                                                    <ul className="list-group">
                                                                        <li className="list-group-item">Timbres restantes:{timbresRestantes}</li>
                                                                        {/*<li className="list-group-item">Timbres restantes:2122</li>
                                                                    <li className="list-group-item list-group-item-danger" style={{}}>Licencia de operador por vencer de operador Héctor Javier</li>
                                                                    <li className="list-group-item">Actualización programada  28-06-2022</li>*/}

                                                                    </ul>

                                                                </div>
                                                            </div>
                                                            <div className="dataTable" style={{ width: '32.5%', marginLeft: '2%' }}>
                                                                <div className="dataTitle" style={{ height: "50px" }}><p className="fixedP" style={{ width: '100%' }}> Cobranza</p></div>
                                                                <div className="dataBox" style={{ height: "auto", padding: '12px' }}>
                                                                    ...
                                                                </div>
                                                            </div>
                                                            <div className="dataTable" style={{ width: '32.5%', marginLeft: '2%' }}>
                                                                <div className="dataTitle" style={{ height: "50px" }}><p className="fixedP" style={{ width: '100%' }}> Configuraciones</p></div>
                                                                <div className="dataBox" style={{ height: "auto", padding: '12px' }}>
                                                                    {isSuperAdmin &&
                                                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                                            <Link onClick={() => setIsOpen(true)} role="button">Sellos de facturación</Link>
                                                                        </Typography>
                                                                    }

                                                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                                        <Link onClick={() => setIsOpenDolarPrice(true)} role="button">Precio del dolar</Link>
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                    <ModalConfiguracioens
                                        currenteditrecrod={null}
                                        isEdit={true}
                                        isOpen={isOpen}
                                        uid={user ? user.uid : null}
                                        adminCurrentUser={null}
                                        isAdmin={false}
                                        toggle={() => setIsOpen(false)} />
                                    <ModalDolarPrice
                                        currenteditrecrod={null}
                                        isEdit={true}
                                        isOpen={isOpenDolarPrice}
                                        uid={user ? user.uid : null}
                                        adminCurrentUser={null}
                                        isAdmin={false}
                                        toggle={() => setIsOpenDolarPrice(false)} />
                                </div>


                            </div>
                        </div>

                    </div>
                }
            </LoadingOverlay>
        </Fragment >
    );
}
export default DashboardContent;