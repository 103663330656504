import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



export default class Download extends React.Component {
    constructor(props)
    {
        super();
        let {data}=props;
        this.state = {
            dataSet1: data,
        }
     //   console.log(this.state);

    }

    componentDidUpdate=(prevProps)=>{
        
    }
    
    render() {
        return (
            <ExcelFile  element={<div><button className="boton secBtn excel ms-right"><span>Excel</span></button></div>}>
                <ExcelSheet data={this.state.dataSet1} name="Factuas"> 
                   <ExcelColumn label="Folio" value="nfactura"/>
                    <ExcelColumn label="Cliente" value="cliente"/>
                    <ExcelColumn label="Referencia" value="referencia"/>
                    <ExcelColumn label="Carta Porte" value="carta_porte"/>
                    <ExcelColumn label="Cantidad Total" value="factura_monto"/>
                    <ExcelColumn label="Moneda" value="moneda"/>
                    <ExcelColumn label="Balance Pendiente" value="pendientes"/>
                    <ExcelColumn label="Fecha" value="fechaformat2"/>
                    <ExcelColumn label="Estatus" value="status"/>
                    <ExcelColumn label="Folio Fiscal" value="foliouuid"/>
                    <ExcelColumn label="Subtotal" value="subtotal"/>
                    <ExcelColumn label="Total" value="totalcfdi"/>
                    <ExcelColumn label="MonedaCFDI" value="monedacfdi"/>
                    <ExcelColumn label="Trasladado" value="trasladado"/>
                    <ExcelColumn label="Retenido" value="retenido"/>
                    
                    
                    
                  
                </ExcelSheet>
               
            </ExcelFile>
        );
    }
}
