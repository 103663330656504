import { firebase } from '../configs/firebase';


export const CopiarOperadoresEntreCuentas=async()=>{
    let uidorigen="1W3ZQxsEpGUo2uEsB5hMgJFiFAj2";
    let uiddestino="adCOj3hocBYbS1Z69GOptc0ukOr1";
    let aux=[];
    await getOperadores(uidorigen).then(snapshot=>{
        snapshot.forEach(document=>{
           let o= {...document.data()};
           o.uid=uiddestino;
           aux.push(o);
        });

    }).catch(err=>console.log(err));


    // Get a new write batch
    var db = firebase.firestore();
    const batch = db.batch();

    // Commit the batch

    aux.forEach((doc) => {
        var docRef = db.collection("operadores_user").doc(); //automatically generate unique id
        batch.set(docRef, doc);
    });

    /*await db.collection('viajes').get().then(resp => {
       resp.docs.forEach((doc) => {
            const docRef = firebase.firestore().collection("viajes").doc(doc.id)
            batch.update(docRef, {isViajeCargado:true});
        })
    });*/
    await batch.commit();
}

export const ActualizacionMasivaTemporal = async () => {
    const parquevehicular =  [
        {
            "Nombre": "Francisco Javier ",
            "Apellidos": "Sánchez Rodríguez",
            "Telefono": "8135249237",
            "RFC": "SARF841203BT5",
            "TipoLicencia": "B",
            "NumeroLicencia": "LFD00004788",
            "ExpiracionLicencia": "5/7/25",
            "NumeroIMSS": "67008414871",
            "cp": "25720",
            "estado": "Coahuila",
            "estado_short": "COA",
            "localidad": "Monclova",
            "localidadSat": "04",
            "municipio": "Monclova",
            "municipioSat": "018",
            "calle": "Benito Juarez",
            "numero": "798",
            "colonia": "Los Pinos",
            "coloniaSat": "0465"
        },
        {
            "Nombre": "Erick Johan",
            "Apellidos": "Rodriguez Medina",
            "Telefono": "5579473312",
            "RFC": "ROME9205033B5",
            "TipoLicencia": "B",
            "NumeroLicencia": "HGO0017768",
            "ExpiracionLicencia": "5/18/27",
            "NumeroIMSS": "94099202874",
            "cp": "25720",
            "estado": "Coahuila",
            "estado_short": "COA",
            "localidad": "Monclova",
            "localidadSat": "04",
            "municipio": "Monclova",
            "municipioSat": "018",
            "calle": "Benito Juarez",
            "numero": "798",
            "colonia": "Los Pinos",
            "coloniaSat": "0465"
        },
        {
            "Nombre": "Mario Alberto",
            "Apellidos": "Ventura López",
            "Telefono": "8180180272",
            "RFC": "VELM861226VEA",
            "TipoLicencia": "B",
            "NumeroLicencia": "SLP0021785",
            "ExpiracionLicencia": "1/12/26",
            "NumeroIMSS": "43048600449",
            "cp": "25720",
            "estado": "Coahuila",
            "estado_short": "COA",
            "localidad": "Monclova",
            "localidadSat": "04",
            "municipio": "Monclova",
            "municipioSat": "018",
            "calle": "Benito Juarez",
            "numero": "798",
            "colonia": "Los Pinos",
            "coloniaSat": "0465"
        },
        {
            "Nombre": "Julio César",
            "Apellidos": "Rojo Ponce",
            "Telefono": "8141099545",
            "RFC": "ROPJ950820E89",
            "TipoLicencia": "B",
            "NumeroLicencia": "LFD01139275",
            "ExpiracionLicencia": "5/3/28",
            "NumeroIMSS": "17159562788",
            "cp": "25720",
            "estado": "Coahuila",
            "estado_short": "COA",
            "localidad": "Monclova",
            "localidadSat": "04",
            "municipio": "Monclova",
            "municipioSat": "018",
            "calle": "Benito Juarez",
            "numero": "798",
            "colonia": "Los Pinos",
            "coloniaSat": "0465"
        }
    ];
    const fixedunidades=parquevehicular.map(element=>{
        let obj = {
            nombre:element.Nombre||"",
            apellidos:element.Apellidos||"",
            ciudad:"",
            celular:element.Telefono||"",
            tipoDePago:"Manual",
            active:true,
            noLicencia:element.NumeroLicencia||"",
            tipoLicencia:element.TipoLicencia||"",
            noIMSS:element.NumeroIMSS||"",
            isMoreData:false,
            direccion:"",
            telefonoEmergencia:"",
            contactoEmergencia:"",
            rfc:element.RFC||"",
            internacional: false,
            estado:element.estado||"",
            estado_short:element.estado_short||"",
            localidad:element.localidad||"",
            localidadSat:element.localidadSat||"",
            municipio:element.municipio||"",
            municipioSat:element.municipioSat||"",
            calle:element.calle||"",
            numero:element.numero||"",
            colonia:element.colonia||"",
            colonias:[],
            coloniaSat:element.coloniaSat||"",
            cp:element.cp||"",
            uid:"xxx",
        };

        return obj;

    })
  

    console.log("unidades user", fixedunidades);
    //    return;

    // Get a new write batch
    var db = firebase.firestore();
    const batch = db.batch();

    // Commit the batch

    fixedunidades.forEach((doc) => {
        var docRef = db.collection("operadores_user").doc(); //automatically generate unique id
        batch.set(docRef, doc);
    });

    /*await db.collection('viajes').get().then(resp => {
       resp.docs.forEach((doc) => {
            const docRef = firebase.firestore().collection("viajes").doc(doc.id)
            batch.update(docRef, {isViajeCargado:true});
        })
    });*/
    await batch.commit();
}
export const getOperadorById = (id) => {
    var db = firebase.firestore();
    return db.collection("operadores_user").doc(id).get();
}

export const getOperadores = (uid) => {
    var db = firebase.firestore();
    return db.collection("operadores_user").where("uid", "==", uid).get();
}

export const addOperador = (data) => {
    let dataaux = { ...data, createAt: new Date() };
    var db = firebase.firestore();
    return db.collection('operadores_user').add(dataaux);
}

export const updateOperador = (id, data) => {
    let dataaux;
    dataaux = { ...data, udpateAt: new Date()};
    var db = firebase.firestore();
    return db.collection('operadores_user').doc(id).update(dataaux);
}

export const deleteOperador = (id) => {
    var db = firebase.firestore();
    return db.collection('operadores_user').doc(id).update({uid:"deleted"});
}
